<template lang="pug">
	.preview
		picture
			source(srcset="/assets/img/landingNew/preview/preview-bg-mobile.webp" media="(max-width: 767px)")
			source(srcset="/assets/img/landingNew/preview/preview-bg.webp" media="(max-width: 1900px)")
			img.preview__bg-img(src="/assets/img/landingNew/preview/preview-bg.webp" alt="preview girl")
		.preview__decor.preview__decor--first
			img(src="/assets/img/landingNew/preview/smiley.svg")
			span(v-html="$t('landingNew.preview.decor[0]')")
		.preview__decor.preview__decor--second
			span(v-html="$t('landingNew.preview.decor[1]')")
			img(src="/assets/img/landingNew/preview/fireworks.svg")
		.preview__decor.preview__decor--third
			span(v-html="$t('landingNew.preview.decor[2]')")
		img.preview__decor-third-img(src="/assets/img/landingNew/preview/heart.svg")
		span.preview__title {{ $t('landingNew.preview.title[0]') }}
		.preview__title-wrap
			span.preview__title(v-html="$t('landingNew.preview.title[1]')")
			span.preview__title.preview__title--blue
				span(id="preview-title-blue")
				span.preview__cursor
		span.preview__title(v-html="$t('landingNew.preview.title[2]')")
		p.preview__description(v-html="$t('landingNew.preview.description')")
		my-link(:text="$t('landingNew.preview.button')" :link="'javascript:void(0)'" @click.native="$emit('click', $event)")
		.preview__list
			.preview__item
				img.preview__img(src="/assets/img/landingNew/preview/01.svg")
				span.preview__text(v-html="$t('landingNew.preview.list[0]')")
			.preview__item
				img.preview__img(src="/assets/img/landingNew/preview/02.svg")
				span.preview__text(v-html="$t('landingNew.preview.list[1]')")
			.preview__item
				img.preview__img(src="/assets/img/landingNew/preview/03.svg")
				span.preview__text(v-html="$t('landingNew.preview.list[2]')")
			.preview__item
				img.preview__img(src="/assets/img/landingNew/preview/04.svg")
				span.preview__text(v-html="$t('landingNew.preview.list[3]')")
			.preview__item
				img.preview__img(src="/assets/img/landingNew/preview/05.svg")
				span.preview__text(v-html="$t('landingNew.preview.list[4]')")
			.preview__item
				img.preview__img(src="/assets/img/landingNew/preview/06.svg")
				span.preview__text(v-html="$t('landingNew.preview.list[5]')")
</template>

<script>
import MyLink from '@/components/LandingNew/UI/MyLink';

export default {
	name: 'Preview',
	components: {
		MyLink,
	},
	data: () => ({
		phraseIndex: 0,
		charIndex: 1,
		chars: 0
	}),
	beforeMount() {
		this.$nextTick(() => {
			this.draw();
		})
	},
	methods: {
		draw() {
			let text = document.querySelector('#preview-title-blue');
			let phrases = [this.$t(`landingNew.preview.words[0]`), this.$t(`landingNew.preview.words[1]`), this.$t(`landingNew.preview.words[2]`)];

			const intervalId = setInterval(() => {
				let phrase = phrases[this.phraseIndex];
				this.chars = phrase.split('');
				text.innerHTML = this.chars.slice(0, this.charIndex).join('');

				if (this.charIndex == this.chars.length) {
					clearInterval(intervalId);

					setTimeout(() => {
						this.charIndex = 0;
					}, 1500);
				} else if (this.charIndex == 0) {
					this.phraseIndex++;
					this.charIndex++

					if (this.phraseIndex === phrases.length) {
						this.phraseIndex = 0;
					}
				} else if (this.charIndex < this.chars.length && this.charIndex != 0) {
					this.charIndex++
				}
			}, 150);
		}
	},
	watch: {
		charIndex(v) {
			if (v == 0) {
				this.draw();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.preview {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 40px 125px 72px;
	background-color: var(--lc-blue);
	border-bottom-left-radius: 45px;
	border-bottom-right-radius: 45px;
	margin-top: -1px;

	@media(max-width: 1900px) {
		padding: 33px 55px 52px;
		border-bottom-left-radius: 40px;
		border-bottom-right-radius: 40px;
	}

	@media(max-width: 1320px) {
		padding: 38px 55px 50px;
	}

	@media(max-width: 991px) {
		padding: 43px 15px 40px;
	}

	@media(max-width: 767px) {
		padding: 10px 15px 30px;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	&__decor {
		position: absolute;
		width: max-content;
		padding: 22px 24px;
		border-radius: 20px;
		box-shadow: 0 0 80px 0 var(--lc-white) inset;
		background-color: rgba(255, 255, 255, 0.7);
		overflow: hidden;

		@media(max-width: 1900px) {
			padding: 17px 23px;
		}

		@media(max-width: 1320px) {
			padding: 13px 15px;
			border-radius: 15px;
		}

		@media(max-width: 991px) {
			padding: 10px 12px;
			border-radius: 13px;
		}

		@media(max-width: 767px) {
			padding: 7px 10px;
			border-radius: 10.5px;
			box-shadow: 0px 0 56px 0 var(--lc-white) inset;
		}

		img {
			position: absolute;
			z-index: 1;
			top: 50%;
			transform: translateY(-50%);
		}

		span {
			position: relative;
			z-index: 1;
			font-size: 24px;

			@media(max-width: 1900px) {
				font-size: 19px;
			}

			@media(max-width: 1320px) {
				font-size: 14px;
			}

			@media(max-width: 991px) {
				font-size: 12px;
			}

			@media(max-width: 767px) {
				font-size: 10px;
			}
		}

		&--first {
			top: 362px;
			right: 112px;
			padding-left: 86px;
			transform: rotate(-12.466deg);

			@media(max-width: 1900px) {
				top: 248px;
				right: 94px;
				padding-left: 52px;
			}

			@media(max-width: 1320px) {
				top: 120px;
				right: 50px;
				padding-left: 48px;
			}

			@media(max-width: 991px) {
				top: 122px;
				right: 10px;
				padding-left: 36px;
			}

			@media(max-width: 767px) {
				top: 208px;
				right: 41px;
				padding-left: 33px;
				transform: rotate(-26.115deg);
			}

			img {
				left: 24px;

				@media(max-width: 1900px) {
					left: 16px;
					width: 28px;
				}

				@media(max-width: 1320px) {
					left: 14px;
					width: 24px;
				}

				@media(max-width: 991px) {
					left: 10px;
					width: 18px;
				}
			}
		}

		&--second {
			top: 480px;
			right: 136px;
			padding-right: 74px;
			transform: rotate(10.76deg);

			@media(max-width: 1900px) {
				top: 340px;
				right: 40px;
				padding-right: 54px;
			}

			@media(max-width: 1320px) {
				top: 192px;
				right: 48px;
				padding-right: 44px;
			}

			@media(max-width: 991px) {
				top: 194px;
				right: 32px;
				padding-right: 34px;
			}

			@media(max-width: 767px) {
				top: 259px;
				right: 25px;
				padding-right: 27px;
				transform: rotate(-2.889deg);
			}

			img {
				right: 16px;

				@media(max-width: 1900px) {
					right: 16px;
					width: 32px;
				}

				@media(max-width: 1320px) {
					right: 12px;
					width: 24px;
				}

				@media(max-width: 991px) {
					right: 10px;
					width: 18px;
				}

				@media(max-width: 767px) {
					width: 16px;
				}
			}
		}

		&--third {
			top: 652px;
			right: 154px;
			padding-right: 46px;
			padding-left: 34px;
			transform: rotate(6.573deg);

			@media(max-width: 1900px) {
				top: 456px;
				right: 72px;
				padding-right: 24px;
				padding-left: 18px;
			}

			@media(max-width: 1320px) {
				top: 290px;
				right: 60px;
				padding-right: 24px;
				padding-left: 13px;
			}

			@media(max-width: 991px) {
				top: 290px;
				right: 52px;
				padding-right: 20px;
				padding-left: 10px;
			}

			@media(max-width: 767px) {
				top: 325px;
				right: 22px;
				padding-right: 12px;
				padding-left: 10px;
				transform: rotate(-7.075deg);
			}
		}
	}

	&__decor-third-img {
		position: absolute;
		top: 678px;
		right: 124px;

		@media(max-width: 1900px) {
			top: 475px;
			right: 48px;
			width: 56px;
		}

		@media(max-width: 1320px) {
			top: 305px;
			right: 43px;
			width: 40px;
		}

		@media(max-width: 991px) {
			top: 304px;
			right: 38px;
			width: 32px;
		}

		@media(max-width: 767px) {
			top: 315px;
			right: 5px;
		}
	}

	&__bg-img {
		position: absolute;
		bottom: 0;
		right: 0;
		height: 1139px;

		@media(max-width: 1900px) {
			height: 825px;
		}

		@media(max-width: 1320px) {
			height: 594px;
		}

		@media(max-width: 991px) {
			height: 494px;
		}

		@media(max-width: 767px) {
			right: auto;
			bottom: 50%;
			top: 182px;
			left: 2px;
			width: 320px;
			height: auto;
		}
	}

	&__title-wrap {
		display: inline-flex;
		align-items: center;
	}

	&__title {
		width: max-content;
		font-size: 100px;
		font-weight: 400;
		line-height: 98%;
		color: var(--lc-white);

		@media(max-width: 1900px) {
			font-size: 70px;
			line-height: 90%;
		}

		@media(max-width: 1320px) {
			font-size: 40px;
			line-height: 98%;
		}

		@media(max-width: 991px) {
			font-size: 36px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
		}

		&--blue {
			min-height: 111px;
			padding: 0 24px 13px;
			color: var(--lc-blue);
			border-radius: 30px;
			background-color: var(--lc-white);
			margin: 21px 0 0;

			@media(max-width: 1900px) {
				min-height: 46.2px;
				padding: 1px 16px 12px;
				border-radius: 30px;
				margin: 12px 0 0;
			}

			@media(max-width: 1320px) {
				padding: 1px 10px 6px;
				border-radius: 15px;
				margin: 7px 0 0;
			}

			@media(max-width: 991px) {
				min-height: 41.28px;
				padding: 1px 10px 5px;
				border-radius: 15px;
				margin: 7px 0 0;
			}

			@media(max-width: 767px) {
				min-height: 35.44px;
				padding: 2px 6px 6px;
				border-radius: 10px;
				margin: 4px 0 2px;
			}
		}
	}

	&__description {
		max-width: 515px;
		font-size: 28px;
		font-weight: 300;
		color: var(--lc-white);
		margin: 40px 0 60px;

		@media(max-width: 1900px) {
			max-width: 420px;
			font-size: 22px;
			margin: 45px 0;
		}

		@media(max-width: 1320px) {
			max-width: 280px;
			font-size: 16px;
			margin: 30px 0 40px;
		}

		@media(max-width: 991px) {
			font-size: 15px;
			margin: 25px 0 35px;
		}

		@media(max-width: 767px) {
			max-width: 270px;
			font-size: 14px;
			margin: 15px 0 237px;
		}
	}

	&__list {
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: space-between;
		margin: 218px -141px 0;

		@media(max-width: 1900px) {
			margin: 125px -116px 0;
		}

		@media(max-width: 1320px) {
			margin: 69px -70px 0;
		}

		@media(max-width: 991px) {
			margin: 56px -34px 0;
		}

		@media(max-width: 767px) {
			flex-wrap: wrap;
			margin: 20px 0 0;
		}
	}

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
		width: 309px;
		height: 133px;
		border-radius: 50px;
		background-color: rgba(116, 184, 255, 0.22);
		box-shadow: 0 0 97px 0 rgba(255, 255, 255, 0.42) inset;
		backdrop-filter: blur(13.5px);
		transition: var(--la-time);

		@media(max-width: 1900px) {
			justify-content: flex-start;
			padding: 26px 18px;
			width: 16%;
			height: 105px;
			border-radius: 30px;

			img {
				width: 53px;
			}
		}

		@media(max-width: 1320px) {
			justify-content: flex-start;
			padding: 18px 10px;
			width: 160px;
			height: 74px;
			border-radius: 20px;

			img {
				width: 37px;
			}
		}

		@media(max-width: 991px) {
			padding: 12px 8px;
			width: percentage(122px / 774px);
			height: 54px;
			border-radius: 18px;

			img {
				width: 28px;
			}
		}

		@media(max-width: 767px) {
			padding: 16px 9px;
			width: calc(50% - 5px);
			height: 61px;
			border-radius: 16.37px;
			box-shadow: 0 0 79.39333px 0 rgba(255, 255, 255, 0.42) inset;
			backdrop-filter: blur(11.049586296081543px);
			margin-top: 10px;

			img {
				width: 30px;
			}
		}
	}

	&__text {
		font-size: 24px;
		color: var(--lc-white);
		margin-left: 30px;
		transition: var(--la-time);

		@media(max-width: 1900px) {
			font-size: 20px;
			margin-left: 16px;
		}

		@media(max-width: 1320px) {
			font-size: 14px;
			margin-left: 11px;
		}

		@media(max-width: 991px) {
			font-size: 10px;
			margin-left: 8px;
		}

		@media(max-width: 767px) {
			font-size: 12px;
			margin-left: 9px;
		}
	}

	&__cursor {
		display: inline-block;
		width: 28px;
		height: 4px;
		background-color: var(--lc-blue);
		margin-left: 2px;
		animation: cursor-blink 1s infinite;

		@media(max-width: 1900px) {
			width: 14px;
			height: 2px;
		}

		@media(max-width: 991px) {
			width: 12px;
			height: 2px;
		}

		@media(max-width: 767px) {
			width: 9px;
			height: 1px;
		}
	}

	@keyframes cursor-blink {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}
</style>
