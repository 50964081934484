<template lang='pug'>
	a.my-link(:href="link" :target="link != 'javascript:void(0)' ? '_blank' : '_self'")
		span.my-link__text {{ text }}
		svg(xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none")
			rect(width="65" height="65" rx="30" fill="white")
			path(d="M24.1438 42.7561L41.756 25.1439M41.756 25.1439H29.4684M41.756 25.1439V37.4315" stroke="#263F5A" stroke-width="3")
</template>
<script>

export default {
	name: 'MyLink',
	props: {
		text: {
			default: '',
			type: String
		},
		link: {
			default: 'javascript:void(0)',
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
.my-link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 26px 30px 26px 50px;
	width: 482px;
	border-radius: 30px;
	background-color: var(--lc-orange);

	@media(max-width: 1900px) {
		padding: 24px 35px;
		width: 416px;
		border-radius: 30px;

		svg {
			width: 53px;
			height: 53px;
		}
	}

	@media(max-width: 1320px) {
		padding: 14px 20px 13px;
		width: 248px;
		border-radius: 15px;

		svg {
			width: 33px;
			height: 33px;
		}
	}

	@media(max-width: 767px) {
		position: relative;
		z-index: 1;
		padding: 14px 20px 13px;
		width: 100%;
		border-radius: 15px;

		svg {
			width: 33px;
			height: 33px;
		}
	}

	&:hover,
	&:active {
		.my-link__text {
			opacity: 0.5;
		}
	}

	&__text {
		font-size: 28px;
		font-weight: 500;
		color: var(--lc-dark) !important;
		transition: var(--la-time);

		@media(max-width: 1900px) {
			font-size: 24px;
		}

		@media(max-width: 1320px) {
			font-size: 16px;
		}

		@media(max-width: 767px) {
			font-size: 16px;
		}
	}
}
</style>
