<template lang="pug">
	.header
		a.header__logo(href="/")
		.header__burger-wrap(v-if="!isOpen")
			span.header__menu-button(@click="changeLocale") {{ $t('landingNew.header.lang') }}
			span.header__burger(@click="open")

		nav.header__nav
			a.header__nav-link(href="#authors") {{ this.$t('landingNew.header.navList[1]') }}
			a.header__nav-link(href="#advantages") {{ this.$t('landingNew.header.navList[2]') }}
			a.header__nav-link(v-if="isLocaleRu" :href="`https://paywall.pw${$t('contacts.blog')}`" target="_blank") {{ this.$t('landingNew.header.navList[3]') }}
			a.header__nav-link(v-if="isLocaleRu" href="#write") {{ this.$t('landingNew.header.navList[4]') }}
			a.header__nav-link(:href="this.$t('contacts.knowledge_base')" target="_blank") {{ this.$t('landingNew.header.navList[7]') }}
			a.header__nav-link(href="/subscriber" target="_blank") {{ this.$t('landingNew.header.navList[6]') }}
		.header__buttons
			span.header__button(@click="$emit('click', $event)") {{ $t('landingNew.header.enter') }}
			span.header__button(@click="changeLocale") {{ $t('landingNew.header.lang') }}

		.header__menu(:class="{'fade-menu': isOpen}")
			span.header__close(@click="close")
			a.header__menu-link(href="#authors"  @click="close") {{ this.$t('landingNew.header.navList[1]') }}
			a.header__menu-link(href="#advantages"  @click="close") {{ this.$t('landingNew.header.navList[2]') }}
			a.header__menu-link(v-if="isLocaleRu" :href="`https://paywall.pw${$t('contacts.blog')}`" target="_blank" @click="close") {{ this.$t('landingNew.header.navList[3]') }}
			a.header__menu-link(v-if="isLocaleRu" href="#write" @click="close") {{ this.$t('landingNew.header.navList[4]') }}
			a.header__menu-link(:href="this.$t('contacts.knowledge_base')" target="_blank" @click="close") {{ this.$t('landingNew.header.navList[7]') }}
			a.header__menu-link(href="/subscriber" target="_blank"  @click="close") {{ this.$t('landingNew.header.navList[6]') }}
			span.header__menu-line
			span.header__menu-button(@click="$emit('click', $event)") {{ $t('landingNew.header.enter') }}
			span.header__menu-button(@click="changeLocale") {{ $t('landingNew.header.lang') }}
</template>

<script>
import { mapActions } from "vuex";
import { localeOptions } from "@/constants/config";

export default {
	name: 'Header',
	data: () => ({
		localeOptions,
		isOpen: false
	}),
	computed: {
		navList() {
			return [
				//{title: this.$t('landingNew.header.navList[0]'), href: "#"},
				{ title: this.$t('landingNew.header.navList[1]'), href: "#authors", locale: true },
				{ title: this.$t('landingNew.header.navList[2]'), href: "#advantages", locale: true },
				{ title: this.$t('landingNew.header.navList[3]'), href: `https://paywall.pw${this.$t('contacts.blog')}`, blank: "_blank", locale: false },
				{ title: this.$t('landingNew.header.navList[4]'), href: "#write", locale: false },
				{ title: this.$t('landingNew.header.navList[7]'), href: this.$t('contacts.knowledge_base'), blank: "_blank", locale: true },
				//{title: this.$t('landingNew.header.navList[5]'), href: "/faq", blank: "_blank"},
				{ title: this.$t('landingNew.header.navList[6]'), href: "/subscriber", blank: "_blank", locale: true }
			]
		}
	},
	created() {
		window.addEventListener('click', e => {
			if (this.isOpen === true) {
				const target = e.target;

				if (!target.closest('.header__menu') && !target.closest('.header__burger')) {
					this.close()
				}
			}
		});
	},
	methods: {
		...mapActions(['setLang']),
		changeLocale() {
			this.setLang(_.filter(localeOptions, l => l.id != this.$i18n.locale)[0].id);
			window.location.reload();
		},
		open() {
			this.isOpen = true;
			document.querySelector('.app').classList.add('overflow')
			document.body.classList.add('is-overflow')
		},
		close() {
			this.isOpen = false;
			document.querySelector('.app').classList.remove('overflow')
			document.body.classList.remove('is-overflow')
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	top: -184px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 45px 125px;
	width: 100%;
	border-top-left-radius: 45px;
	border-top-right-radius: 45px;
	background-color: var(--lc-blue);
	transition: top var(--la-time) linear;

	@media(max-width: 1900px) {
		padding: 36px 48.5px;
		border-top-left-radius: 40px;
		border-top-right-radius: 40px;
	}

	@media(max-width: 1320px) {
		padding: 20px 55px;
	}

	@media(max-width: 991px) {
		padding: 15px 25px;
	}

	@media(max-width: 767px) {
		padding: 20px 15px 10px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	&__logo {
		width: 200px;
		height: 50px;
		mask-image: url('/assets/img/landingNew/header/logo.svg');
		mask-size: cover;
		background-color: var(--lc-white);
		transition: var(--la-time);

		@media(max-width: 1900px) {
			width: 152px;
			height: 38px;
		}

		@media(max-width: 1320px) {
			width: 114px;
			height: 28px;
		}

		@media(max-width: 991px) {
			width: 90px;
			height: 21px;
		}

		@media(max-width: 767px) {
			width: 101px;
			height: 24px;
		}

		&:hover,
		&:active {
			opacity: 0.5;
		}
	}

	&__nav {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;

		@media(max-width: 767px) {
			display: none;
		}
	}

	&__nav-link {
		font-size: 18px;
		font-weight: 300;
		color: var(--lc-white);
		margin-right: 35px;
		transition: var(--la-time);

		&:last-child {
			margin-right: 0;
		}

		&:hover,
		&:active {
			opacity: 0.5;
			color: var(--lc-white) !important;
		}

		@media(max-width: 1900px) {
			font-weight: 350;
			margin-right: 35px;
		}

		@media(max-width: 1320px) {
			font-size: 12px;
			font-weight: 300;
			margin-right: 20px;
		}

		@media(max-width: 991px) {
			font-size: 11px;
			margin-right: 12px;
		}
	}

	&__buttons {
		position: relative;
		z-index: 1;
		display: flex;

		@media(max-width: 767px) {
			display: none;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		font-size: 24px;
		font-weight: 500;
		color: var(--lc-white);
		padding: 20px 26px;
		border-radius: 20px;
		border: 2px solid rgba(255, 255, 255, 0.19);
		transition: var(--la-time);
		cursor: pointer;

		&:first-child {
			width: 167px;
			margin-right: 15px;
		}

		&:hover,
		&:active {
			opacity: 0.5;
			color: var(--lc-white) !important;
		}

		@media(max-width: 1900px) {
			font-size: 24px;
			padding: 19.5px 26px;
			border-radius: 20px;

			&:first-child {
				width: 167px;
			}
		}

		@media(max-width: 1320px) {
			font-size: 14px;
			padding: 14px 21px;
			border-radius: 15px;

			&:first-child {
				width: 98px;
			}
		}

		@media(max-width: 991px) {
			font-size: 13px;
			font-weight: 400;
			padding: 7px 10px;
			border-radius: 10px;

			&:first-child {
				width: auto;
				margin-right: 6px;
			}
		}
	}

	&__burger-wrap {
		display: none;
		align-items: center;

		.header__menu-button {
			margin: 0 20px 0 0;
		}

		@media(max-width: 767px) {
			display: flex;
		}
	}

	&__burger {
		display: none;

		@media(max-width: 767px) {
			display: block;
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/landingNew/header/burger.svg');
			mask-size: cover;
			background-color: var(--lc-white);
		}
	}

	&__menu {
		display: none;

		@media(max-width: 767px) {
			z-index: 100;
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			padding: 20px 30px;
			width: 240px;
			height: 100%;
			background-color: var(--lc-blue);
			transform: translateX(100%);
			transition: all 200ms;
			overflow: auto;

			&.fade-menu {
				transform: translateX(0);
				transition: all 200ms;
			}
		}
	}

	&__close {
		@media(max-width: 767px) {
			position: absolute;
			top: 24px;
			right: 20px;
			width: 16px;
			height: 16px;
			background-image: url('/assets/img/landingNew/header/close.svg');
			background-size: cover;
		}
	}

	&__menu-line {
		display: block;
		width: 100%;
		height: 1px;
		background-color: var(--lc-white);
		opacity: 0.5;
		margin-bottom: 20px;
	}

	&__menu-link {
		@media(max-width: 767px) {
			width: max-content;
			font-size: 16px;
			font-weight: 300;
			color: var(--lc-white);
			margin-bottom: 20px;

			&:hover,
			&:focus {
				color: var(--lc-white);
			}
		}
	}

	&__menu-button {
		@media(max-width: 767px) {
			width: max-content;
			font-size: 16px;
			font-weight: 300;
			color: var(--lc-white);
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.header-sticky {
		z-index: 98;
		position: sticky;
		top: 22px;
		border-radius: 45px;
		background-color: var(--lc-white);
		box-shadow: 0 11px 26px 5px rgba(64, 153, 247, 0.12);
		transition: top var(--la-time) linear;

		@media(max-width: 1900px) {
			border-radius: 40px;
		}

		@media(max-width: 1320px) {
			border-radius: 30px;
		}

		@media(max-width: 991px) {
			top: 8px;
		}

		@media(max-width: 767px) {
			top: 0;
			padding: 20px 15px;
			border-radius: 0;
		}

		.header__logo {
			background-color: var(--lc-blue);
		}

		.header__burger-wrap {
			.header__menu-button {
				color: var(--lc-blue);
			}
		}

		.header__burger {
			@media(max-width: 767px) {
				background-color: var(--lc-blue);
			}
		}

		.header__nav-link {
			color: var(--lc-blue);

			&:hover,
			&:active {
				color: var(--lc-blue) !important;
			}
		}

		.header__button {
			color: var(--lc-blue);
			border-color: var(--lc-blue);

			&:hover,
			&:active {
				color: var(--lc-blue) !important;
			}
		}
	}
}
</style>
