<template lang="pug">
	.footer
		.footer__top
			nav.footer__nav
				a.footer__link(href="/")
					img.footer__logo(src="/assets/img/landingNew/footer/logo.svg" alt="Paywall")

			.footer__socials.footer__socials--mobile(v-if="socials")
				a.footer__social-link(v-for="(social, i) in socials" :key="`social-${i}`" :href="social.href" target="_blank")
					img.footer__social-image(:src="social.src" :alt="social.name")

			nav.footer__nav
				a.footer__nav-link(href="#authors") {{ this.$t('landingNew.header.navList[1]') }}
				a.footer__nav-link(href="#advantages") {{ this.$t('landingNew.header.navList[2]') }}
				a.footer__nav-link(v-if="isLocaleRu" :href="`https://paywall.pw${$t('contacts.blog')}`" target="_blank") {{ this.$t('landingNew.header.navList[3]') }}
				a.footer__nav-link(v-if="isLocaleRu" href="#write") {{ this.$t('landingNew.header.navList[4]') }}
				a.footer__nav-link(:href="this.$t('contacts.knowledge_base')" target="_blank") {{ this.$t('landingNew.header.navList[7]') }}
				a.footer__nav-link(href="/subscriber" target="_blank") {{ this.$t('landingNew.header.navList[6]') }}

			nav.footer__nav
				a.footer__nav-link(href="mailto:mail@paywall.pw") mail@paywall.pw

			.footer__socials.footer__socials--desktop(v-if="socials")
				a.footer__social-link(v-for="(social, i) in socials" :key="`social-${i}`" :href="social.href" target="_blank")
					img.footer__social-image(:src="social.src" :alt="social.name")

		.footer__bottom
			span {{ `© 2020–${currentYear} Paywall` }}
			a(href="/terms" target="_blank") {{ $t('landingNew.footer.terms') }}
			a(href="/privacy" target="_blank") {{ $t('landingNew.footer.privacy') }}
</template>

<script>
import { socials } from "@/constants/config";

export default {
	name: 'Footer',
	computed: {
		socials() {
			return socials.apply(this);
		},
		currentYear() {
			return new Date().getFullYear();
		}
	}
}
</script>

<style lang="scss" scoped>
.footer {
	padding: 120px 122px 60px;

	@media(max-width: 1900px) {
		padding: 80px 59px;
	}

	@media(max-width: 1320px) {
		padding: 40px 56px 35px;
	}

	@media(max-width: 767px) {
		padding: 50px 15px 39px;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		padding-bottom: 57px;

		@media(max-width: 1900px) {
			padding-bottom: 87px;
		}

		@media(max-width: 1320px) {
			padding-bottom: 52px;
		}

		@media(max-width: 767px) {
			justify-content: flex-start;
			flex-wrap: wrap;
			padding-bottom: 30px;
		}
	}

	&__link {
		width: max-content;
		height: max-content;
		transition: var(--la-time);

		&:hover,
		&:active {
			opacity: 0.5;
		}
	}

	&__logo {
		width: 200px;
		height: 50px;

		@media(max-width: 1320px) {
			width: 114px;
			height: 28px;
		}

		@media(max-width: 767px) {
			width: 93px;
			height: 22px;
		}
	}

	&__nav {
		display: flex;
		flex-direction: column;
		width: 25%;

		@media(max-width: 767px) {
			width: 50%;
			margin-top: 20px;

			&:first-child {
				width: max-content;
				margin-top: 0;
			}
		}
	}

	&__nav-link {
		width: max-content;
		font-size: 20px;
		line-height: 150%;
		/* 30px */
		color: var(--lc-black);
		margin-bottom: 14px;
		transition: var(--la-time);

		@media(max-width: 1900px) {
			line-height: normal;
		}

		@media(max-width: 1320px) {
			font-size: 14px;
			margin-bottom: 10px;
		}

		@media(max-width: 767px) {
			margin-bottom: 12px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover,
		&:active {
			opacity: 0.5;
			color: var(--lc-black) !important;
		}
	}

	&__socials {
		justify-content: flex-end;
		width: 25%;

		@media(max-width: 1900px) {
			flex-wrap: wrap;
			height: max-content;
		}

		@media(max-width: 767px) {
			justify-content: flex-start;
			width: 65%;
		}

		&--desktop {
			display: flex;

			@media(max-width: 767px) {
				display: none;
			}
		}

		&--mobile {
			display: none;

			@media(max-width: 767px) {
				display: flex;
			}
		}
	}

	&__social-link {
		width: 30px;
		min-width: 30px;
		height: 30px;
		min-height: 30px;
		transition: var(--la-time);
		margin-right: 17px;
		border-radius: 50%;

		@media(max-width: 1900px) {
			width: 30px;
			min-width: 30px;
			height: 30px;
			min-height: 30px;
		}

		@media(max-width: 1320px) {
			width: 28px;
			min-width: 28px;
			height: 28px;
			min-height: 28px;
			margin: 0 0 17px 17px;
		}

		@media(max-width: 767px) {
			width: 27px;
			min-width: 27px;
			height: 27px;
			min-height: 27px;
			margin: 0 0 10px 20px;
		}

		&:hover,
		&:active {
			opacity: 0.5;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__social-image {
		width: 100%;
		height: 100%;
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 20px;
		border-top: 1px solid rgba(#000, 0.1);

		@media(max-width: 1900px) {
			padding-top: 87px;
		}

		@media(max-width: 1320px) {
			padding-top: 20px;
		}

		@media(max-width: 767px) {
			flex-direction: column-reverse;
			justify-content: flex-start;
			align-items: flex-start;
			padding-top: 10px;
		}

		span {
			font-size: 16px;
			line-height: 150%;
			/* 24px */
			color: var(--lc-black);
			opacity: 0.3;

			@media(max-width: 1320px) {
				font-size: 12px;
			}

			@media(max-width: 767px) {
				margin-top: 10px;
			}
		}

		a {
			font-size: 16px;
			line-height: 150%;
			/* 24px */
			color: var(--lc-black);
			transition: var(--la-time);
			opacity: 0.3;

			@media(max-width: 1320px) {
				font-size: 12px;
			}

			@media(max-width: 767px) {
				margin-top: 10px;
			}

			&:hover,
			&:active {
				opacity: 0.5;
				color: var(--lc-black) !important;
			}
		}
	}
}</style>
