<template lang="pug">
	.care
		span.care__title {{ $t('landingNew.care.title[0]') }}
		span.care__title {{ $t('landingNew.care.title[1]') }}
			span.care__title.care__title--white {{ $t('landingNew.care.title[2]') }}
		p.care__description(v-html="$t('landingNew.care.description')")
		.care__info
			.care__left
				img.care__left-img(src="/assets/img/landingNew/care/avatar.webp")
				.care__item-1
					p(v-html="$t('landingNew.care.left[0]')")
					img(src="/assets/img/landingNew/care/icon-1.svg")
				.care__item-2
					p(v-html="$t('landingNew.care.left[1]')")
			.care__right
				.care__right-title(v-html="$t('landingNew.care.right.title')")
				.care__right-text(v-html="$t('landingNew.care.right.text')")
</template>

<script>
export default {
	name: 'Care',
}
</script>

<style lang="scss" scoped>
.care {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 133px 279px 190px;
	background-color: var(--lc-white);

	@media(max-width: 1900px) {
		padding: 80px 70px 210px;
	}

	@media(max-width: 1320px) {
		padding: 60px 70px 80px;
	}

	@media(max-width: 991px) {
		padding: 50px 50px 60px;
	}

	@media(max-width: 767px) {
		align-items: flex-start;
		padding: 30px 15px;
	}

	&__title {
		display: flex;
		width: max-content;
		font-size: 83px;
		font-weight: 400;
		line-height: 90%;
		/* 74.7px */
		color: var(--lc-blue);
		text-align: center;
		margin-bottom: 20px;

		@media(max-width: 1900px) {
			font-size: 55px;
			line-height: normal;
			margin-bottom: 0;
		}

		@media(max-width: 1320px) {
			font-size: 40px;
			line-height: normal;
			margin-bottom: 7px;
		}

		@media(max-width: 991px) {
			font-size: 36px;
			margin-bottom: 3px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
			line-height: 100%;
			/* 28px */
			text-align: start;
			margin-bottom: 7px;
		}

		&--white {
			padding: 5px 15px 20px;
			color: var(--lc-white);
			border-radius: 30px;
			background-color: var(--lc-blue);
			margin: 0 0 33px 20px;

			@media(max-width: 1900px) {
				padding: 1px 30px 9px;
				font-size: 55px;
				line-height: normal;
				border-radius: 30px;
				margin: 0 0 38px 18px;
			}

			@media(max-width: 1320px) {
				padding: 1px 10px 8px;
				font-size: 40px;
				line-height: 90%;
				/* 36px */
				border-radius: 15px;
				margin: 0 0 23px 10px;
			}

			@media(max-width: 991px) {
				padding: 1px 10px 8px;
				font-size: 36px;
				margin: 0 0 15px 10px;
			}

			@media(max-width: 767px) {
				padding: 2px 5px 6px;
				font-size: 28px;
				border-radius: 10px;
				margin: 0 0 8px 7px;
			}
		}
	}

	&__description {
		max-width: 1048px;
		font-size: 20px;
		font-weight: 300;
		text-align: center;
		margin: 0 0 138px;

		@media(max-width: 1900px) {
			max-width: 769px;
			font-size: 20px;
			margin: 0 0 45px;
		}

		@media(max-width: 1320px) {
			max-width: 726px;
			font-size: 14px;
			margin: 0 0 80px;
		}

		@media(max-width: 991px) {
			max-width: 726px;
			font-size: 13px;
			margin: 0 0 50px;
		}

		@media(max-width: 767px) {
			font-size: 14px;
			text-align: start;
			margin: 0 0 25px;
		}
	}

	&__info {
		display: flex;

		@media(max-width: 767px) {
			flex-direction: column;
		}
	}

	&__left {
		position: relative;
		width: 50%;

		@media(max-width: 767px) {
			width: 100%;
		}
	}

	&__left-img {
		position: absolute;
		z-index: 1;
		top: -85px;
		left: -185px;
		width: 85px;
		height: 85px;
		border-radius: 50%;

		@media(max-width: 1900px) {
			top: -48px;
			left: 4px;
			width: 85px;
			height: 85px;
		}

		@media(max-width: 1320px) {
			top: -45px;
			left: -36px;
			width: 51px;
			height: 51px;
		}

		@media(max-width: 991px) {
			top: -35px;
			left: -40px;
			width: 48px;
			height: 48px;
		}


		@media(max-width: 767px) {
			z-index: 1;
			top: 0px;
			left: 198px;
			width: 36px;
			height: 36px;
		}
	}

	&__item-1 {
		position: absolute;
		top: -6px;
		left: -172px;
		display: flex;
		align-items: center;
		padding: 0 32px 0 52px;
		width: 556px;
		height: 190px;
		border-radius: 51px;
		border: 2px solid var(--lc-grizzle);
		background-color: transparent;
		transform: rotate(-6.244deg);

		@media(max-width: 1900px) {
			top: 0;
			left: 0px;
			padding: 34px 32px;
			width: 464px;
			height: 163px;
			border-radius: 30px;
		}

		@media(max-width: 1320px) {
			top: 0;
			left: 0px;
			padding: 12px 20px;
			width: 289.295px;
			height: 104.468px;
			border-radius: 24.108px;
			border: 1.607px solid var(--lc-grizzle);
		}

		@media(max-width: 767px) {
			position: static;
			padding: 10px 30px 10px 20px;
			width: 207px;
			height: 75px;
			border-radius: 17.308px;
			border: 1.154px solid var(--lc-grizzle);
			margin-left: 8px;
		}

		img {
			position: absolute;
			top: 32px;
			right: -60px;
			width: 97px;
			height: 97px;
			transform: rotate(6.244deg);

			@media(max-width: 1900px) {
				top: 132px;
				right: -96px;
				width: 76px;
				height: 76px;
			}

			@media(max-width: 1320px) {
				top: 32px;
				right: -26px;
				width: 40px;
				height: 40px;
			}

			@media(max-width: 767px) {
				top: 25px;
				left: -13px;
				right: auto;
				width: 26px;
				height: auto;
				transform: rotate(-28.906deg);
			}
		}

		p {
			font-size: 30px;
			font-weight: 300;
			line-height: 33.052px;
			/* 110.175% */
			color: var(--lc-azure);
			opacity: 0.65;
			margin: 0;

			@media(max-width: 1900px) {
				font-size: 22px;
				line-height: 33.052px;
				/* 150.238% */
			}

			@media(max-width: 1320px) {
				font-size: 14px;
				line-height: 24.108px;
				/* 172.2% */
			}

			@media(max-width: 767px) {
				font-size: 10px;
				line-height: 17.308px
			}
		}
	}

	&__item-2 {
		position: absolute;
		bottom: -48px;
		left: 0;
		display: flex;
		align-items: center;
		padding: 0 45px;
		width: 577px;
		height: 213px;
		border-radius: 51px;
		background-color: var(--lc-grizzle);
		transform: rotate(5.372deg);

		@media(max-width: 1900px) {
			bottom: -124px;
			left: 96px;
			padding: 4px 32px;
			width: 511px;
			height: 178px;
			border-radius: 30px;
		}

		@media(max-width: 1320px) {
			bottom: -24px;
			left: 102px;
			padding: 4px 32px;
			width: 299px;
			height: 105px;
			border-radius: 24.108px;
		}

		@media(max-width: 991px) {
			left: 31px;
			padding: 4px 16px;
			width: 269px;
			height: 105px;
		}

		@media(max-width: 767px) {
			position: static;
			padding: 10px 30px 10px 20px;
			width: 214px;
			height: 75px;
			border-radius: 17.308px;
			transform: rotate(4.475deg);
			margin: 4px 0 28px auto;
		}

		p {
			font-size: 30px;
			font-weight: 400;
			line-height: 33.052px;
			/* 110.175% */
			color: var(--lc-black);
			margin: 0;

			@media(max-width: 1900px) {
				font-size: 22px;
				line-height: 33.052px;
				/* 150.238% */
			}

			@media(max-width: 1320px) {
				font-size: 14px;
				line-height: 24.108px;
				/* 172.2% */
			}

			@media(max-width: 767px) {
				font-size: 10px;
				line-height: 17.308px;
				/* 173.077% */
			}
		}
	}

	&__right {
		width: 50%;
		padding-left: 22px;

		@media(max-width: 1900px) {
			padding-left: 76px;
		}

		@media(max-width: 1320px) {
			padding-left: 0;
		}

		@media(max-width: 767px) {
			width: 100%;
		}
	}

	&__right-title {
		font-size: 48px;
		margin-bottom: 40px;
		color: var(--lc-blue);

		@media(max-width: 1900px) {
			max-width: 493px;
			font-size: 32px;
			margin-bottom: 25px;
		}

		@media(max-width: 1320px) {
			max-width: 100%;
			font-size: 28px;
		}

		@media(max-width: 991px) {
			font-size: 22px;
			margin-bottom: 18px;
		}

		@media(max-width: 767px) {
			font-size: 18px;
			margin-bottom: 15px;
		}
	}

	&__right-text {
		font-size: 24px;
		font-weight: 300;

		@media(max-width: 1900px) {
			font-size: 20px;
		}

		@media(max-width: 1320px) {
			font-size: 14px;
		}

		@media(max-width: 767px) {
			font-size: 14px;
			margin-bottom: 0;
		}
	}
}
</style>
