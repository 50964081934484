<template lang="pug">
	.articles
		p.articles__title(v-html="$t('landingNew.articles.title')")

		.articles__list(v-if="articles")
			a.articles__item(v-for="(item, i) in articles" :key="`article-${i}`" :href="item.link" target="_blank")
				img.articles__item-img(:src="item.yoast_head_json.og_image[0].url" :alt="item.title.rendered")
				p.articles__item-title(v-html="item.title.rendered")
				span.articles__item-date {{ item.date_gmt | dateDDMMMYYYYDots }}

		flickity.articles__flickity(ref="flickity" :options="flickityOptions" :key="key")
			a.articles__item(v-for="(item, i) in articles" :key="`articles-${i}`" :href="item.link" target="_blank")
				img.articles__item-img(:src="item.yoast_head_json.og_image[0].url" :alt="item.title.rendered")
				p.articles__item-title(v-html="item.title.rendered")
				span.articles__item-date {{ item.date_gmt | dateDDMMMYYYYDots }}

		.articles__buttons
			span.articles__button(@click="previous()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M0.292893 6.8593C-0.0976311 7.24982 -0.0976311 7.88299 0.292893 8.27351L6.65685 14.6375C7.04738 15.028 7.68054 15.028 8.07107 14.6375C8.46159 14.2469 8.46159 13.6138 8.07107 13.2233L2.41421 7.56641L8.07107 1.90955C8.46159 1.51903 8.46159 0.885863 8.07107 0.495338C7.68054 0.104814 7.04738 0.104814 6.65685 0.495338L0.292893 6.8593ZM17 6.56641H1V8.56641H17V6.56641Z" fill="#263F5A")


			span.articles__button(@click="next()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M16.7071 8.27351C17.0976 7.88299 17.0976 7.24982 16.7071 6.8593L10.3431 0.495338C9.95262 0.104814 9.31946 0.104814 8.92893 0.495338C8.53841 0.885863 8.53841 1.51903 8.92893 1.90955L14.5858 7.56641L8.92893 13.2233C8.53841 13.6138 8.53841 14.2469 8.92893 14.6375C9.31946 15.028 9.95262 15.028 10.3431 14.6375L16.7071 8.27351ZM0 8.56641H16V6.56641H0V8.56641Z" fill="#263F5A")

		my-link.articles__btn(:text="$t('landingNew.articles.button')" :link="'https://paywall.pw/blog'")
</template>

<script>
import axios from 'axios';
import { APIURL } from '@/api/index.js';
import MyLink from '@/components/LandingNew/UI/MyLink';
import Flickity from 'vue-flickity';

export default {
	name: 'Articles',
	components: {
		MyLink,
		Flickity
	},
	data: () => ({
		flickityOptions: {
			initialIndex: 1,
			prevNextButtons: false,
			pageDots: false,
			autoPlay: false,
			wrapAround: true,
			groupCells: 1
		},
		articles: [],
		key: 0
	}),
	mounted() {
		this.getArticles();
	},
	methods: {
		async getArticles() {
			try {
				const responce = await axios.get(`${APIURL}public/blog/posts`);
				this.articles = responce.data;
				this.key++
			} catch (error) {
			}
		},
		next() {
			this.$refs.flickity.next();
		},

		previous() {
			this.$refs.flickity.previous();
		}
	}
}
</script>

<style lang="scss">
.articles {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 120px 116px 87px;
	background-color: var(--lc-white);

	@media(max-width: 1900px) {
		padding: 80px 70px;
	}

	@media(max-width: 1320px) {
		padding: 60px 70px;
	}

	@media(max-width: 991px) {
		padding: 50px 20px;
		overflow-x: hidden;
	}

	@media(max-width: 767px) {
		align-items: flex-start;
		padding: 24px 15px 30px;
		border-radius: 20px;
	}

	&__title {
		font-size: 83px;
		line-height: 90%;
		/* 74.7px */
		text-align: center;
		color: var(--lc-blue);
		margin-bottom: 96px;

		@media(max-width: 1900px) {
			font-size: 55px;
			margin-bottom: 50px;
		}

		@media(max-width: 1320px) {
			font-size: 40px;
			margin-bottom: 32px;
		}

		@media(max-width: 991px) {
			font-size: 36px;
			margin-bottom: 24px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
			text-align: start;
			margin-bottom: 25px;
		}
	}

	&__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 70px;

		@media(max-width: 1900px) {
			margin-bottom: 50px;
		}

		@media(max-width: 1320px) {
			margin-bottom: 30px;
		}

		@media(max-width: 767px) {
			display: none;
		}
	}

	&__flickity {
		display: none;

		@media(max-width: 767px) {
			display: block;
			width: 100%;

			.flickity-viewport {
				overflow: visible !important;
			}
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		padding: 23px 27px 25px;
		width: 522px;
		min-height: 476px;
		border-radius: 38px;
		border: 2px solid var(--lc-light);
		transition: var(--la-time);
		margin: 0 33px 33px 0;

		@media(max-width: 1900px) {
			padding: 20px;
			width: calc(32% - 5px);
			min-height: 375px;
			border-radius: 30px;
			margin: 0 30px 30px 0;
		}

		@media(max-width: 1320px) {
			padding: 14px 15px 16px;
			width: calc(32% - 5px);;
			min-height: 258px;
			border-radius: 20px;
			border: 1.081px solid var(--lc-light);
			margin: 0 20px 20px 0;
		}

		@media(max-width: 991px) {
			padding: 9px 10px 11px;
			width: calc(32% - 2px);;
			min-height: 218px;
			border-radius: 18px;
			margin: 0 15px 15px 0;
		}

		@media(max-width: 767px) {
			padding: 15px 18px;
			width: 100%;
			min-height: 260px;
			border-radius: 20px;
			border: 1.092px solid var(--lc-light);
			margin: 0 15px 0 0;
			transition: none;
		}

		&:hover,
		&:active {
			background-color: var(--lc-pale);

			@media(max-width: 767px) {
				background-color: transparent;
			}
		}

		&:last-child,
		&:nth-child(3) {
			margin-right: 0;
		}
	}

	&__item-img {
		width: 100%;
		height: 264px;
		object-fit: cover;
		border-radius: 38px;
		margin-bottom: 30px;

		@media(max-width: 1900px) {
			height: 208px;
			border-radius: 26px;
			margin-bottom: 20px;
		}

		@media(max-width: 1320px) {
			height: 143px;
			border-radius: 20px;
			margin-bottom: 15px;
		}

		@media(max-width: 991px) {
			border-radius: 18px;
		}

		@media(max-width: 767px) {
			width: 100%;
			height: auto;
			object-fit: contain;
			border-radius: 20px;
			margin-bottom: 14px;
		}
	}

	&__item-title {
		flex-grow: 1;
		font-size: 24px;
		color: var(--lc-black);
		max-height: 92px;

		-webkit-line-clamp: 3;
		/* Число отображаемых строк */
		display: -webkit-box;
		/* Включаем флексбоксы */
		-webkit-box-orient: vertical;
		/* Вертикальная ориентация */
		overflow: hidden;
		/* Обрезаем всё за пределами блока */

		@media(max-width: 1900px) {
			width: 100%;
			font-size: 20px;
			margin-bottom: 15px;
		}

		@media(max-width: 1320px) {
			font-size: 14px;
			margin-bottom: 14px;
		}

		@media(max-width: 991px) {
			width: 100%;
			font-size: 13px;
			margin-bottom: 12px;
		}

		@media(max-width: 767px) {
			width: 100%;
			font-size: 14px;
			margin-bottom: 14px;
		}
	}


	&__item-date {
		font-size: 20px;
		font-weight: 350;
		color: var(--lc-black);
		opacity: 0.5;

		@media(max-width: 1900px) {
			font-size: 20px;
		}

		@media(max-width: 1320px) {
			font-size: 12px;
		}
	}

	&__btn {
		width: 482px;

		@media(max-width: 767px) {
			width: 100%;
			margin-top: 30px;
		}
	}

	&__buttons {
		display: none;

		@media(max-width: 767px) {
			display: flex;
			margin-top: 30px;
		}
	}

	&__button {
		@media(max-width: 767px) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 46px;
			height: 46px;
			border-radius: 50%;
			background-color: #EBF5FF;

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}
</style>
