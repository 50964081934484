<template lang="pug">
	.steps
		p.steps__title {{ $t('landingNew.steps.title[0]') }}
		p.steps__title {{ $t('landingNew.steps.title[1]') }}
			span {{ $t('landingNew.steps.title[2]') }}

		.steps__list
			.steps__item.steps__item--left
				span.steps__circle
				p.steps__item-title(v-html="$t('landingNew.steps.list[0].title')")
				p.steps__item-text(v-html="$t('landingNew.steps.list[0].text')")
			.steps__item.steps__item--center
				span.steps__circle
				p.steps__item-title(v-html="$t('landingNew.steps.list[1].title')")
				p.steps__item-text(v-html="$t('landingNew.steps.list[1].text')")
			.steps__item.steps__item--right
				span.steps__circle
				p.steps__item-title(v-html="$t('landingNew.steps.list[2].title')")
				p.steps__item-text(v-html="$t('landingNew.steps.list[2].text')")

		p.steps__desc(v-html="$t('landingNew.steps.desc')")

		my-link.steps__button(:text="$t('landingNew.steps.button')" :link="'javascript:void(0)'" @click.native="$emit('click', $event)")
</template>

<script>
import MyLink from '@/components/LandingNew/UI/MyLink';

export default {
	name: 'Steps',
	components: {
		MyLink,
	}
}
</script>

<style lang="scss">
.steps {
	display: flex;
	flex-direction: column;
	padding: 110px 141px 152px;
	background-color: var(--lc-white);

	@media(max-width: 1900px) {
		padding: 80px 70px;
	}

	@media(max-width: 1320px) {
		padding: 60px 70px;
	}

	@media(max-width: 991px) {
		padding: 50px 20px;
	}

	@media(max-width: 767px) {
		padding: 30px 15px;
	}

	&__title {
		font-size: 83px;
		line-height: 90%;
		/* 74.7px */
		text-align: center;
		color: var(--lc-blue);
		margin-bottom: 30px;

		@media(max-width: 1900px) {
			font-size: 55px;
			line-height: normal;
			margin-bottom: 0;
		}

		@media(max-width: 1320px) {
			font-size: 40px;
			margin-bottom: 3px;
		}

		@media(max-width: 991px) {
			font-size: 36px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
			text-align: start;
			margin-bottom: 5px;
		}

		span {
			display: inline-block;
			padding: 8px 15px 17px;
			font-size: 83px;
			font-style: normal;
			font-weight: 400;
			line-height: 90%;
			/* 74.7px */
			color: var(--lc-white);
			border-radius: 30px;
			background-color: var(--lc-blue);
			margin-left: 20px;
			margin-bottom: 100px;

			@media(max-width: 1900px) {
				padding: 0 30px 11px;
				font-size: 55px;
				line-height: normal;
				border-radius: 30px;
				margin-left: 18px;
				margin-bottom: 45px;
			}

			@media(max-width: 1320px) {
				padding: 1px 10px 8px;
				font-size: 40px;
				line-height: 90%;
				/* 36px */
				border-radius: 15px;
				margin-left: 10px;
				margin-bottom: 27px;
			}

			@media(max-width: 991px) {
				font-size: 36px;
				margin-bottom: 20px;
			}

			@media(max-width: 767px) {
				padding: 2px 5px 6px;
				font-size: 28px;
				border-radius: 10px;
				margin-left: 10px;
				margin-bottom: 25px;
			}
		}
	}

	&__list {
		display: flex;
		justify-content: space-between;
		margin-bottom: 90px;

		@media(max-width: 1900px) {
			margin-bottom: 45px;
		}

		@media(max-width: 1320px) {
			margin-bottom: 50px;
		}

		@media(max-width: 991px) {
			margin-bottom: 35px;
		}

		@media(max-width: 767px) {
			flex-direction: column;
			margin-bottom: 25px;
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 234px 81px 42px 34px;
		height: 498px;
		border-radius: 50px;
		background-color: var(--lc-light);
		background-repeat: no-repeat;
		background-position: top right;
		transition: var(--la-time);

		@media(max-width: 1900px) {
			padding: 172px 30px 32px;
			height: 375px;
			border-radius: 50px;
			background-size: 70%;
		}

		@media(max-width: 1320px) {
			padding: 107px 15px 20px 20px;
			height: 270px;
			border-radius: 30px;
			background-size: 60%;
		}

		@media(max-width: 991px) {
			padding: 90px 7px 15px 15px;
			height: 230px;
		}

		@media(max-width: 767px) {
			padding: 86px 20px 20px;
			width: 100%;
			height: max-content;
			background-size: 100%;
		}

		&--left {
			width: calc(33.33% - 20px);
			background-image: url('/assets/img/landingNew/steps/bg-mask/bg-1.svg');

			.steps__circle {
				background-image: url('/assets/img/landingNew/steps/icon/icon-1.svg');
			}

			@media(max-width: 1900px) {
				width: calc(33.33% - 17.5px);
			}

			@media(max-width: 1320px) {
				width: calc(33.33% - 10.6px);
			}

			@media(max-width: 767px) {
				width: 100%;
				background-size: 60%;
				margin-bottom: 10px;
			}
		}

		&--center {
			width: calc(33.33% - 20px);
			background-image: url('/assets/img/landingNew/steps/bg-mask/bg-2.svg');

			.steps__circle {
				background-image: url('/assets/img/landingNew/steps/icon/icon-2.svg');
			}

			@media(max-width: 1900px) {
				width: calc(33.33% - 17.5px);
			}

			@media(max-width: 1320px) {
				width: calc(33.33% - 10.6px);
			}

			@media(max-width: 767px) {
				width: 100%;
				margin-bottom: 10px;
			}
		}

		&--right {
			width: calc(33.33% - 20px);
			background-image: url('/assets/img/landingNew/steps/bg-mask/bg-3.svg');

			.steps__circle {
				background-image: url('/assets/img/landingNew/steps/icon/icon-3.svg');
			}

			@media(max-width: 1900px) {
				width: calc(33.33% - 17.5px);
			}

			@media(max-width: 1320px) {
				width: calc(33.33% - 10.6px);
			}

			@media(max-width: 767px) {
				width: 100%;
			}
		}
	}

	&__circle {
		position: absolute;
		top: 39px;
		left: 34px;
		width: 95px;
		height: 95px;
		border-radius: 50%;
		background-color: var(--lc-white);
		background-repeat: no-repeat;
		background-position: center;

		@media(max-width: 1900px) {
			top: 20px;
			left: 20px;
			width: 85px;
			height: 85px;
			background-size: 60%;
		}

        @media(max-width: 1320px) {
			top: 30px;
			left: 30px;
			width: 50px;
			height: 50px;
			background-size: 58%;
		}

		@media(max-width: 991px) {
			top: 15px;
			left: 15px;
			width: 45px;
			height: 45px;
			background-size: 53%;
		}

		@media(max-width: 767px) {
			top: 20px;
			left: 20px;
			width: 50px;
			height: 50px;
			background-size: 58%;
		}
	}

	&__item-title {
		font-size: 35px;
		line-height: 110%;
		/* 38.5px */
		margin-bottom: 24px;
		transition: var(--la-time);

		@media(max-width: 1900px) {
			font-size: 28px;
			margin-bottom: 20px;
		}

        @media(max-width: 1320px) {
			font-size: 22px;
			margin-bottom: 15px;
		}

		@media(max-width: 991px) {
			font-size: 19px;
			margin-bottom: 10px;
		}

		@media(max-width: 767px) {
			font-size: 16px;
			line-height: 110%;
			margin-bottom: 15px;
		}
	}


	&__item-text {
		font-size: 20px;
		font-weight: 300;
		transition: var(--la-time);
		margin-bottom: 0;

		@media(max-width: 1900px) {
			font-size: 18px;
		}

        @media(max-width: 1320px) {
			font-size: 14px;
		}

		@media(max-width: 991px) {
			font-size: 12px;
		}

		@media(max-width: 767px) {
			font-size: 14px;
		}
	}

	&__desc {
		max-width: 1023px;
		font-size: 32px;
		text-align: center;
		margin: 0 auto 60px;

		@media(max-width: 1900px) {
			max-width: 919px;
			font-size: 26px;
			margin: 0 auto 45px;
		}

        @media(max-width: 1320px) {
			max-width: 669px;
			font-size: 20px;
			margin: 0 auto 30px;
		}

		@media(max-width: 991px) {
			max-width: 640px;
			font-size: 18px;
		}

		@media(max-width: 767px) {
			font-size: 18px;
			text-align: start;
		}
	}

	&__button {
		width: 482px;
		margin: 0 auto;

		@media(max-width: 767px) {
			width: 100%;
		}
	}
}
</style>
